"use strict";

var globalLastElementClicked = "";
var reverseOutdeferred = null;
var scenes = [];
var sceneHelper = {
	addScene: function (scene, el) {
		scenes.push({
			scene: scene,
			element: el,
		});
	},
	destroyScene: function (el) {
		scenes.forEach(function (elem) {
			if (elem.element === el) {
				elem.scene.destroy(true);
				var ind = scenes.indexOf(elem);
				if (ind > -1) {
					scenes.splice(ind, 1);
				}
			}
		});
	},
};

var timelines = [];
var timelineHelper = {
	addTimeline: function (timeline, el, type) {
		timelines.push({
			timeline: timeline,
			element: el,
			type: type,
		});
	},
	reverse: function (el, callback) {
		timelines.forEach(function (elem) {
			if (elem.element === el) {
				var ind = timelines.indexOf(elem);
				timelines[ind].timeline.reverse();
			}
		});
	},
	destroyTimeline: function (el) {
		timelines.forEach(function (elem) {
			if (elem.element === el) {
				// VEDERE COME SI DISTRUGGE UNA TIMELINE IN TWEEN
				//elem.scene.destroy(true);
				var ind = timelines.indexOf(elem);
				if (ind > -1) {
					timelines.splice(ind, 1);
				}
			}
		});
	},
};

var controllers = [];
var controllerHelper = {
	addController: function (controller, controllerName) {
		controllers.push({
			controller: controller,
			controllerName: controllerName,
		});
	},
	destroyController: function (name) {
		controllers.forEach(function (elem) {
			if (elem.controllerName === name) {
				elem.controller.destroy(true);
				var ind = controllers.indexOf(elem);
				if (ind > -1) {
					controllers.splice(ind, 1);
				}
			}
		});
	},
};

//var firstLoading = true;

var InTimeLineOptions = {
	paused: true,
	onComplete: function () {
		//if (firstLoading) {
		siteInit.inview();
		siteInit.reveal();
		//firstLoading = false;
		//}
		console.log("loader complete event");
		$(document).trigger("loaderInComplete");
	},
};
var OutTimeLineOptions = {
	paused: true,
	onComplete: function () {
		console.log("loader Out complete event");
		$(document).trigger("loaderOutComplete");
	},
};

var EndTimeLineOptions = {
	paused: true,
	onComplete: function () {
		siteInit.inview();
		siteInit.reveal();

		console.log("loader End complete event");
		$(document).trigger("loaderEndComplete");
	},
};

var $reveal = $(".loader__reveal");
var $revealTop = $(".loader__reveal-top");
var $loader = $(".loader");
var $loaderImg = $(".loader__img");
var $loaderRing = $(".loader__ring");
var timeLineLoaderIn = new TimelineMax(InTimeLineOptions);
var timeLineLoaderOut = new TimelineMax(OutTimeLineOptions);
var timeLineLoaderEnd = new TimelineMax(EndTimeLineOptions);

timeLineLoaderIn
	.add("end", 0.5)
	.add("end2", 0)
	.to($loaderImg, 1.0, { opacity: 0, top: "40%" })
	.to($loaderRing, 1.0, { opacity: 0, top: "40%" }, "end2")
	.to($revealTop, 1.5, { bottom: "100%", ease: Quint.easeInOut }, "end2")
	.to($reveal, 1.5, { bottom: "100%", ease: Quint.easeInOut }, "end")
	.to($loader, 0, { display: "none" });

timeLineLoaderOut
	.add("end", 0.5)
	.add("end2", 0)
	.to($loader, 0, { display: "block" })
	.to($loaderImg, 1.0, { opacity: 1, top: "50%" })
	.to($loaderRing, 1.0, { opacity: 1, top: "50%" }, "end")
	.set($revealTop, { bottom: "0", top: "100%", ease: Quint.easeInOut }, "end")
	.to(
		$revealTop,
		1.5,
		{ bottom: "0%", top: "0%", ease: Quint.easeInOut },
		"end"
	)
	.set($reveal, { bottom: "0%", top: "100%", ease: Quint.easeInOut }, "end2")
	.to(
		$reveal,
		1.5,
		{ bottom: "0%", top: "0%", ease: Quint.easeInOut },
		"end2"
	);

timeLineLoaderEnd
	.add("end", 0.5)
	.add("end2", 0)
	.to($loaderImg, 1.0, { opacity: 0, top: "50%" })
	.to($loaderRing, 1.0, { opacity: 0, top: "50%" }, "end2")
	//.set($revealTop, { bottom: "0", top: "60%", ease:Quint.easeInOut }, "end")
	.to(
		$revealTop,
		1.5,
		{ bottom: "100%", top: "0%", ease: Quint.easeInOut },
		"end2"
	)
	//.set($reveal, { bottom: "0%", top: "60%", ease:Quint.easeInOut}, "end2")
	.to(
		$reveal,
		1.5,
		{ bottom: "100%", top: "0%", ease: Quint.easeInOut },
		"end"
	)
	.to($loader, 0, { display: "none" });

// 	// Loader
// var loader = {

// 		playLoader: function() {
// 			timeLineLoaderIn.play();
// 		},

// 		pauseLoader: function() {
// 			timeLineLoaderIn.pause();
// 		},

// 		reverseLoader: function() {
// 			timeLineLoaderIn.reverse();
// 		}

// },

var siteInit = {
	DOMready: function () {
		if ($("#js-wrapper").length > 0) {
			//siteInit.barba();
		}

		// Differenziarlo troppo specifico
		if ($(".js-scroll").length > 0) {
			//siteInit.scrollMagic();
		}

		if ($(".js-parallax").length > 0) {
			////siteInit.parallax();
		}

		if ($(".js-reveal").length > 0) {
			siteInit.reveal();
		}

		if ($(".js-slider--primary").length > 0) {
			siteInit.sliderPrimary();
		}

		if ($(".js-slider--tertiary").length > 0) {
			siteInit.sliderTertiary();
		}

		if ($('[href$=".jpg"], [href$=".png"], [href$=".gif"]').length > 0) {
			//siteInit.modal();
		}

		if ($(".js-navicon").length > 0) {
			siteInit.mobileNavigation();
		}

		//siteInit.photoswipe();

		if ($(".js-header").length > 0) {
			siteInit.scrollMenu();
		}

		siteInit.inview();

		if ($(".js-scroll-menu").length > 0) {
			siteInit.scrollToMenu();
		}
	},

	DOMload: function () {
		if ($(".js-loader").length > 0) {
			//timeLineLoaderIn.play();
		}

		if ($(".js-video").length > 0) {
			siteInit.video();
		}
	},

	isTouchDevice: function () {
		return (
			true ===
			("ontouchstart" in window ||
				(window.DocumentTouch && document instanceof DocumentTouch))
		);
	},

	windowScroll: function () {},

	windowResize: function () {},

	loadCF7Script: function () {
		var homeUrl = $("body").data("domain");
		$.getScript(
			homeUrl +
				"/wp-content/plugins/contact-form-7/includes/js/scripts.js",
			function () {
				console.log("ciao cf7");
			}
		);
	},

	scrollToMenu: function () {
		$(".js-scroll-menu").on("click", function (e) {
			e.preventDefault();
			var pad = 40;
			var href = $(this).attr("href");

			if (window.matchMedia("(min-width: 768px)").matches) {
				if ($("body").hasClass("is-top")) {
					pad = 280;
				}
			}

			$(href).animatescroll({
				padding: pad,
			});
		});
	},

	video: function () {
		$(".js-video").trigger("play");

		if (siteInit.isTouchDevice() === false) {
			$(".js-video").prop("muted", false); //unmute
		}
	},

	inview: function () {
		$(".js-inview").on("inview", function (event, isInView) {
			if (isInView) {
				// element is now visible in the viewport
				$(this).addClass("animate animate--fade-in-up");
			} else {
				// element has gone out of viewport
			}
		});
	},

	scrollMenu: function () {
		$(".js-header").scrollMenu({
			addClassTo: "body",
		});
	},

	parallax: function () {
		var parallaxScene;
		var controllerParallax = new ScrollMagic.Controller();
		controllerHelper.addController(controllerParallax, "parallax");

		$(".js-parallax").each(function (index, el) {
			var element = el;
			var selector = $(element);
			var from = selector.data("parallax-from");
			var to = selector.data("parallax-to");
			var duration = selector.data("parallax-duration");

			if (duration == null) {
				duration = "100%";
			}

			var timeLineParallax = new TimelineMax();
			timelineHelper.addTimeline(
				timeLineParallax,
				selector.attr("id"),
				"parallax"
			);

			timeLineParallax.fromTo(
				selector,
				1,
				{ y: from, force3D: true },
				{ y: to, force3D: true }
			);
			parallaxScene = new ScrollMagic.Scene({
				//duration: 0,
				duration: duration,
				//duration: "100%",
				//offset: 0,
				//offset: "-250%",
				triggerElement: element,
				triggerHook: "onEnter",
				reverse: true,
				loglevel: 2,
			})
				.setTween(timeLineParallax)
				/*.addIndicators({
				name: "Indicator - " + index,
				colorStart: "pink",
				colorEnd: "yellow",
				colorTrigger: "brown",
			})*/
				.addTo(controllerParallax);

			sceneHelper.addScene(parallaxScene, selector.attr("id"));
		});
	},

	// Barba
	barba: function () {
		// Variabile per l'ultimo link cliccato
		var lastElementClicked;

		// Cambia la classe di default al wrapper
		Barba.Pjax.Dom.wrapperId = "js-wrapper";

		// Cambia la classe di default al container
		Barba.Pjax.Dom.containerClass = "js-container";

		// Inizializza il plugin
		Barba.Pjax.start();

		// Attiva il prefetching al mouseover/touchstart sul link
		Barba.Prefetch.init();

		// $(document).on('loaderOutComplete', function() {
		// 	console.log('start load complete');
		// 	LoadTransition.newContainerLoading.then(LoadTransition.finish.bind(LoadTransition));
		// });

		var LoadTransition = Barba.BaseTransition.extend({
			start: function () {
				console.log("Start");
				var _this = this;
				console.log(this);
				$(document).on("loaderOutComplete", function () {
					console.log("start load complete");
					_this.newContainerLoading.then(_this.finish.bind(_this));
				});
				timeLineLoaderOut.play();
			},

			finish: function () {
				console.log("Finish");
				document.body.scrollTop = 0;
				var _this = this;
				//$(document).on('loaderInComplete', function() {
				//	console.log('In complete');
				_this.done();
				//});
			},
		});

		var FadeTransition = Barba.BaseTransition.extend({
			// Chiamata quando parte la transizione
			start: function () {
				// Quando il caricamento è finito e il vecchio container è andato in faded out, fa il fade in della nuova pagina
				Promise.all([this.newContainerLoading, this.fadeOut()]) // this.newContainerLoading è una Promise per il caricamento del nuovo container
					//.all([this.newContainerLoading, this.scrollTop()])
					.then(this.fadeIn.bind(this));
			},

			scrollTop: function () {
				var deferred = Barba.Utils.deferred();
				var obj = { y: window.pageYOffset };

				TweenLite.to(obj, 0.4, {
					y: 0,
					onUpdate: function () {
						if (obj.y === 0) {
							deferred.resolve();
						}

						window.scroll(0, obj.y);
					},
					onComplete: function () {
						//this.fadeOut()
						deferred.resolve();
					},
				});

				return deferred.promise;
			},

			fadeOut: function () {
				// this.oldContainer ritorna l'elemento del vecchio container
				this.scrollTop();
				return $(this.oldContainer).animate({ opacity: 0 }).promise();
			},

			fadeIn: function () {
				// this.newContainer ritorna l'elemento del nuovo container. è disponibile dopo che newContainerLoading è risolto ( A questo punto si trova nel dom con visibility: hidden )
				var $el = $(this.newContainer);
				var _this = this;

				$(this.oldContainer).hide();

				$el.css({ visibility: "visible", opacity: 0 }).animate(
					{ opacity: 1 },
					400,
					function () {
						// Deve essere chiamata quando la transazione è finita (Rimuove il vecchio container dal DOM)
						_this.done();
					}
				);
			},
		});

		// Estendo le nuove transizioni
		Barba.Pjax.getTransition = function () {
			//return LoadTransition;
			return FadeTransition;
		};

		Barba.Dispatcher.on("linkClicked", function (HTMLElement, MouseEvent) {
			// Quando Clicco su un link valido

			// Salvo l'ultimo link cliccato
			lastElementClicked = HTMLElement;
		});

		Barba.Dispatcher.on("initStateChange", function (currentStatus) {
			// Quando il link è cambiato (Esempio: Aggiorna analytics)
		});

		Barba.Dispatcher.on(
			"newPageReady",
			function (
				currentStatus,
				prevStatus,
				HTMLElementContainer,
				newPageRawHTML
			) {
				// Quando il nuovo container è stato caricato e iniettato nel wrapperd
				//siteInit.scrollMagic();
				siteInit.sliderTertiary();
				siteInit.sliderPrimary();
				//siteInit.mobileNavigation();
				siteInit.scrollMenu();
				//siteInit.parallax();
				//siteInit.reveal();
				//siteInit.inview();
				siteInit.reveal();
				//$('.wpcf7-form').wpcf7InitForm();

				if ($(".js-video").length > 0) {
					siteInit.video();
				}

				siteInit.loadCF7Script();

				console.log("Finito");
				//timeLineLoaderOut.reverse();
				timeLineLoaderEnd.play();
			}
		);

		Barba.Dispatcher.on(
			"transitionCompleted",
			function (currentStatus, prevStatus) {
				// Quando la transizione è completata e il vecchio contenitore è stato rimosso
			}
		);

		// Creo del codice specifico solo per una determinata vista
		var about = Barba.BaseView.extend({
			// Il nome della vista ( Aggiungere al container data-namespace="about" )
			namespace: "about",
			onEnter: function () {
				// Il container è pronto e inserito nel DOM
			},
			onEnterCompleted: function () {
				// La transizione è finita
			},
			onLeave: function () {
				// La trasizione verso la nuova pagina è appena iniziata
			},
			onLeaveCompleted: function () {
				// Il container è stato rimosso dal DOM
			},
		});

		// Inizializzo la vista
		about.init();
	},

	scrollMagic: function () {
		// Inizializzo il controller di Scroll Magic
		var controller = new ScrollMagic.Controller({
			container: window,
			vertical: true,
			globalSceneOptions: {},
			loglevel: 2,
			refreshInterval: 100,
		});

		var scene;

		$(".js-scroll").each(function (index, el) {
			var timeLineOptions = {
				delay: 0.5,
				paused: true,
				onComplete: function () {
					console.log("animation is complete");
				},
				onCompleteScope: function () {
					console.log("animation scope is complete");
				},
				tweens: [],
				stagger: 0,
				align: "normal",
				useFrames: false,
				onStart: function () {
					console.log("on start called");
				},
				onStartScope: function () {
					console.log("on start scope called");
				},
				onUpdate: function () {
					console.log("on update called");
				},
				onUpdateScope: function () {
					console.log("on update scope called");
				},
				onRepeat: function () {
					console.log("on repeat called");
				},
				onRepeatScope: function () {
					console.log("on repeat scope called");
				},
				onReverseComplete: function () {
					console.log("on reverse complete");
				},
				onReverseCompleteScope: function () {
					console.log("on reverse complete scope called");
				},
				autoRemoveChildren: false,
				smoothChildTiming: false,
				repeat: 0,
				repeatDelay: 0,
				yoyo: false,
				onCompleteParams: [],
				onReverseCompleteParams: [],
				onStartParams: [],
				onUpdateParams: [],
				onRepeatParams: [],
			};
			var timeLineParallax = new TimelineMax(timeLineOptions);
			var element = el;
			var selector = $(element);
			var from = selector.data("parallax-from");
			var to = selector.data("parallax-to");
			var index = index + 1;

			if (scene != null) {
				//scene.destroy(true);
			}

			timeLineParallax.fromTo(
				selector,
				1,
				{ y: from, force3D: true },
				{ y: to, force3D: true }
			);

			scene = new ScrollMagic.Scene({
				duration: 0,
				offset: 0,
				triggerElement: element,
				triggerHook: 0.5,
				reverse: true,
				loglevel: 2,
			})
				.setClassToggle(element, "is-visible")
				.on("enter", function (event) {
					//console.log('ENTRATO' + index);
					//selector.addClass('is-visible');
				})
				.setTween(timeLineParallax)
				//.setTween(selector, 0.5, {backgroundColor: "green", scale: 2.5}) // trigger a TweenMax.to tween
				.addIndicators({
					name: "Indicator - " + index,
					colorStart: "green",
					colorEnd: "red",
					colorTrigger: "blue",
				}) // add indicators (requires plugin)
				.addTo(controller); // assign the scene to the controller
		});
	},

	reveal: function () {
		var revealScene;
		var controllerReveal = new ScrollMagic.Controller({
			globalSceneOptions: {
				triggerHook: "onEnter",
				duration: "100%",
			},
		});

		controllerHelper.addController(controllerReveal, "reveal");

		$(".js-reveal").each(function (index, el) {
			var element = el;
			var selector = $(element);
			var delay = selector.data("delay");
			if (delay == null) {
				delay = 0.5;
			}
			var tmax_options = {
				delay: delay,
				paused: true,
			};
			var timeLineReveal = new TimelineMax(tmax_options);
			timelineHelper.addTimeline(
				timeLineReveal,
				selector.attr("id"),
				"reveal"
			);
			var index = index + 1;
			$(element).append('<div class="reveal"></div>');
			$(element).append('<div class="reveal-bg"></div>');
			var reveal = $(".reveal");
			var revealBg = $(".reveal-bg");

			timeLineReveal
				.to(reveal, 0.4, { right: 0, ease: Quint.easeInOut })
				.to(revealBg, 0.2, { opacity: 0, ease: Quint.easeInOut })
				.set(revealBg, { display: "none" })
				.to(reveal, 0.3, { left: "100%", ease: Quint.easeIn });
			revealScene = new ScrollMagic.Scene({
				//duration: 800,    // the scene should last for a scroll distance of 100px
				//offset: -200,    // start this scene after scrolling for 50px
				triggerElement: element,
			})
				//.setClassToggle( element, "is-visible")
				.on("enter", function (event) {
					timeLineReveal.play();
					selector.addClass("is-visible");
				})
				//.setTween(timeLineParallax)
				//.setTween(selector, 0.5, {backgroundColor: "green", scale: 2.5}) // trigger a TweenMax.to tween
				/*.addIndicators({
				name: "Indicator - " + index,
				colorStart: "green",
				colorEnd: "red",
				colorTrigger: "blue",
			}) // add indicators (requires plugin)*/
				.addTo(controllerReveal); // assign the scene to the controller

			sceneHelper.addScene(revealScene, selector.attr("id"));
		});
	},

	photoswipe: function () {
		//    src: 'http://www.officinesandrini.it/img/scale/chiocciola/skulptura/oro/scala-elicoidale-in-foglia-oro-06.jpg',

		var initPhotoSwipeFromDOM = function (gallerySelector) {
			// parse slide data (url, title, size ...) from DOM elements
			// (children of gallerySelector)
			var parseThumbnailElements = function (el) {
				var thumbElements = el.childNodes,
					numNodes = thumbElements.length,
					items = [],
					figureEl,
					linkEl,
					size,
					item;

				for (var i = 0; i < numNodes; i++) {
					figureEl = thumbElements[i]; // <figure> element

					// include only element nodes
					if (figureEl.nodeType !== 1) {
						continue;
					}

					linkEl = figureEl.children[0]; // <a> element

					size = linkEl.getAttribute("data-size").split("x");

					// create slide object
					item = {
						src: linkEl.getAttribute("href"),
						w: parseInt(size[0], 10),
						h: parseInt(size[1], 10),
					};

					if (figureEl.children.length > 1) {
						// <figcaption> content
						item.title = figureEl.children[1].innerHTML;
					}

					if (linkEl.children.length > 0) {
						// <img> thumbnail element, retrieving thumbnail url
						item.msrc = linkEl.children[0].getAttribute("src");
					}

					item.el = figureEl; // save link to element for getThumbBoundsFn
					items.push(item);
				}

				return items;
			};

			// find nearest parent element
			var closest = function closest(el, fn) {
				return el && (fn(el) ? el : closest(el.parentNode, fn));
			};

			// triggers when user clicks on thumbnail
			var onThumbnailsClick = function (e) {
				e = e || window.event;
				e.preventDefault ? e.preventDefault() : (e.returnValue = false);

				var eTarget = e.target || e.srcElement;

				// find root element of slide
				var clickedListItem = closest(eTarget, function (el) {
					return el.tagName && el.tagName.toUpperCase() === "FIGURE";
				});

				if (!clickedListItem) {
					return;
				}

				// find index of clicked item by looping through all child nodes
				// alternatively, you may define index via data- attribute
				var clickedGallery = clickedListItem.parentNode,
					childNodes = clickedListItem.parentNode.childNodes,
					numChildNodes = childNodes.length,
					nodeIndex = 0,
					index;

				for (var i = 0; i < numChildNodes; i++) {
					if (childNodes[i].nodeType !== 1) {
						continue;
					}

					if (childNodes[i] === clickedListItem) {
						index = nodeIndex;
						break;
					}
					nodeIndex++;
				}

				if (index >= 0) {
					// open PhotoSwipe if valid index found
					openPhotoSwipe(index, clickedGallery);
				}
				return false;
			};

			// parse picture index and gallery index from URL (#&pid=1&gid=2)
			var photoswipeParseHash = function () {
				var hash = window.location.hash.substring(1),
					params = {};

				if (hash.length < 5) {
					return params;
				}

				var vars = hash.split("&");
				for (var i = 0; i < vars.length; i++) {
					if (!vars[i]) {
						continue;
					}
					var pair = vars[i].split("=");
					if (pair.length < 2) {
						continue;
					}
					params[pair[0]] = pair[1];
				}

				if (params.gid) {
					params.gid = parseInt(params.gid, 10);
				}

				return params;
			};

			var openPhotoSwipe = function (
				index,
				galleryElement,
				disableAnimation,
				fromURL
			) {
				var pswpElement = document.querySelectorAll(".pswp")[0],
					gallery,
					options,
					items;

				items = parseThumbnailElements(galleryElement);

				// define options (if needed)
				options = {
					// define gallery index (for URL)
					galleryUID: galleryElement.getAttribute("data-pswp-uid"),

					getThumbBoundsFn: function (index) {
						// See Options -> getThumbBoundsFn section of documentation for more info
						var thumbnail =
								items[index].el.getElementsByTagName("img")[0], // find thumbnail
							pageYScroll =
								window.pageYOffset ||
								document.documentElement.scrollTop,
							rect = thumbnail.getBoundingClientRect();

						return {
							x: rect.left,
							y: rect.top + pageYScroll,
							w: rect.width,
						};
					},
				};

				// PhotoSwipe opened from URL
				if (fromURL) {
					if (options.galleryPIDs) {
						// parse real index when custom PIDs are used
						// http://photoswipe.com/documentation/faq.html#custom-pid-in-url
						for (var j = 0; j < items.length; j++) {
							if (items[j].pid == index) {
								options.index = j;
								break;
							}
						}
					} else {
						// in URL indexes start from 1
						options.index = parseInt(index, 10) - 1;
					}
				} else {
					options.index = parseInt(index, 10);
				}

				// exit if index not found
				if (isNaN(options.index)) {
					return;
				}

				if (disableAnimation) {
					options.showAnimationDuration = 0;
				}

				// Pass data to PhotoSwipe and initialize it
				gallery = new PhotoSwipe(
					pswpElement,
					PhotoSwipeUI_Default,
					items,
					options
				);
				gallery.init();
			};

			// loop through all gallery elements and bind events
			var galleryElements = document.querySelectorAll(gallerySelector);

			for (var i = 0, l = galleryElements.length; i < l; i++) {
				galleryElements[i].setAttribute("data-pswp-uid", i + 1);
				galleryElements[i].onclick = onThumbnailsClick;
			}

			// Parse URL and open gallery if it contains #&pid=3&gid=1
			var hashData = photoswipeParseHash();
			if (hashData.pid && hashData.gid) {
				openPhotoSwipe(
					hashData.pid,
					galleryElements[hashData.gid - 1],
					true,
					true
				);
			}
		};

		// execute above function
		initPhotoSwipeFromDOM(".my-gallery");
	},

	// Slider
	sliderPrimary: function () {
		var sync1 = $(".js-slider--sync1");
		var sync2 = $(".js-slider--sync2");

		sync1
			.owlCarousel({
				items: 1,
				loop: true,
				autoplay: true,
				nav: false,
				dots: false,
			})
			.on("initialized.owl.carousel", function (event) {
				console.log(event);
			})
			.on("changed.owl.carousel", function (event) {
				var currentItem = event.item.index;
				var items = event.item.count;
				sync2.trigger("to.owl.carousel", [currentItem, 500]);
				$(".gallery__number").html(currentItem + 1 + "/" + items);
			});

		sync2
			.owlCarousel({
				items: 1,
				loop: true,
				nav: false,
				dots: false,
			})
			.on("changed.owl.carousel", function (event) {
				var currentItem = event.item.index;
				sync1.trigger("to.owl.carousel", [currentItem, 500]);
			});
	},

	// Slider
	sliderTertiary: function () {
		$(".js-slider--tertiary").owlCarousel({
			loop: false,
			margin: 0,
			nav: false,
			autoplay: false,
			URLhashListener: true,
			startPosition: "URLHash",
			animateOut: "fadeOut",
			items: 1,
			onChanged: callback,
			responsive: {
				0: {
					dots: true,
				},
				992: {
					dots: false,
				},
			},
		});

		function callback(event) {
			// Provided by the core
			var element = event.target; // DOM element, in this example .owl-carousel
			var name = event.type; // Name of the event, in this example dragged
			var namespace = event.namespace; // Namespace of the event, in this example owl.carousel
			var items = event.item.count; // Number of items
			var item = event.item.index; // Position of the current item
			// Provided by the navigation plugin
			var pages = event.page.count; // Number of pages
			var page = event.page.index; // Position of the current page
			var size = event.page.size; // Number of items per page
		}

		$(".js-slider-hover").on("mouseover", function (e) {
			//e.preventDefault();
			var index = $(this).data("item");
			$(".js-slider-hover").removeClass("is-active");
			$(".js-slider-hover").removeClass("is-active");
			$(this).addClass("is-active");
			$(".js-slider--tertiary").trigger("to.owl.carousel", [
				index,
				1,
				true,
			]);
		});
	},

	// Modal
	modal: function () {
		$('[href$=".jpg"], [href$=".png"], [href$=".gif"]').colorbox({
			transition: "elastic",
			speed: 400,
			opacity: 0.8,
			slideshow: true,
			slideshowSpeed: 4000,
			itemsnitialWidth: 50,
			initialHeight: 50,
			maxWidth: "90%",
			maxHeight: "90%",
		});
	},

	// Mobile navigation
	mobileNavigation: function () {
		$("body").on("click", ".js-navicon", function (event) {
			// Verificare se si puo usare il target CSS al posto di fare il toggle via js
			var $el = $(this).add("#navigation");
			$($el).toggleClass("is-active");
			event.preventDefault();
		});
	},
};

$(document).ready(function () {
	siteInit.DOMready();
});

$(window).load(function () {
	siteInit.DOMload();
});

$(window).scroll(function () {
	siteInit.windowScroll();
});

$(window).resize(function () {
	siteInit.windowResize();
});
